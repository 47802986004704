import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "../components/BackgroundImage"

const StyledHero = styled.div`
  .hero {
    position: relative;
    height: 600px;
    @media (max-width: 320px) {
      height: 540px;
    }
    @media (min-width: 768px) {
      height: 100vh;
      min-height: 750px;
    }
    .hero-bg {
      content: "";
      display: block;
      width: 100%;
      height: 640px;
      background-size: cover;
      background-position: center;
      position: fixed;
      z-index: -1;
      transition: 0.3s;
      @media (min-width: 768px) {
        height: 100%;
      }
    }
    .hero-inner {
      @media (min-width: 768px) {
        max-width: 1000px;
        margin: 0 auto;
      }
      .hero-copy {
        position: absolute;
        color: #fff;
        top: 50%;
        left: 4%;
        transform: translateY(-50%);
        width: 92%;
        margin: auto;
        white-space: pre-line;
        @media (min-width: 768px) {
          left: initial;
          width: 80%;
          padding: 0 1.5rem;
        }
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 100%;
          padding: 0 2rem;
        }
        .parallax {
          will-change: transform;
        }
        .hero-animation {
          .hero-copy--main {
            opacity: 0;
            font-size: 2.6rem;
            line-height: 1.6;
            animation-duration: 1.2s;
            animation-name: FadeIn;
            animation-fill-mode: forwards;
            animation-delay: 1.5s;
            @media (max-width: 320px) {
              font-size: 2.4rem;
            }
            @media (min-width: 768px) {
              font-size: 5rem;
              line-height: 1.7;
            }
          }
          .hero-copy--caption {
            font-size: 1.3rem;
            letter-spacing: 0.06em;
            padding: 1rem 0.3rem 0;
            opacity: 0;
            animation-duration: 1.5s;
            animation-name: FadeIn;
            animation-fill-mode: forwards;
            animation-delay: 2.5s;
            @media (max-width: 320px) {
              font-size: 1.15rem;
            }
            @media (min-width: 768px) {
              font-size: 2rem;
              padding: 2.5rem 0.5rem 0;
            }
          }
        }
      }
    }
    @keyframes FadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .hero {
    &.en {
      .hero-inner {
        .hero-copy {
          @media (min-width: 768px) {
            width: 85%;
          }
          .hero-copy--main {
            font-size: 3.8rem;
            letter-spacing: 0.04em;
            line-height: 1.2;
            margin-bottom: 2rem;
            @media (max-width: 320px) {
              font-size: 3.2rem;
            }
            @media (min-width: 768px) {
              font-size: 7rem;
              line-height: 1.1;
            }
          }
          .hero-copy--caption {
            font-size: 1.2rem;
            margin-top: 0.5rem;
            @media (max-width: 320px) {
              font-size: 1rem;
            }
            @media (min-width: 768px) {
              font-size: 1.6rem;
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
`

const Hero = props => {
  const lang = props.lang
  let heroCopy, heroCaption
  if (lang === "ja") {
    heroCopy = `古民家 箕澤屋は
    江戸の趣を感じる
    ちょっと素敵な空間です。`
    heroCaption = "Experience History in a traditional Japanese house"
  } else {
    heroCopy = `Experience History
    in a traditional Japanese house`
    heroCaption = "信州伊那谷にあるちょっと素敵な古民家、箕澤屋です。"
  }

  const { mobileImage, desktopImage } = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "home/img-hero-small.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      desktopImage: file(relativePath: { eq: "home/img-hero-medium.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  useEffect(() => {
    const parallax = document.querySelector(".parallax")
    const heroBG = document.querySelector(".hero-bg")

    window.addEventListener("scroll", function() {
      const YOffset = window.pageYOffset
      var rate = YOffset * 0.35
      if (YOffset < 1000) {
        parallax.style.transform = `translateY(${rate}px)`
        heroBG.style.display = `block`
      } else {
        heroBG.style.display = `none`
      }
    })
  })

  return (
    <StyledHero>
      <div className={`hero ${lang} font-serif`}>
        <BackgroundImage bgImage={sources} className="hero-bg fadein" />
        <div className="hero-inner">
          <div className="hero-copy">
            <div className="hero-animation parallax">
              <p className="hero-copy--main">{heroCopy}</p>
              <aside className="hero-copy--caption">{heroCaption}</aside>
            </div>
          </div>
        </div>
      </div>
    </StyledHero>
  )
}

export default Hero
