import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteTitleEn
            siteDescription
            siteDescriptionEn
            siteKeywords
            siteKeywordsEn
            siteUrl
            ogpImage
            author
          }
        }
      }
    `
  )

  // Page Data
  const lang = props.lang
  const pageTitle = props.pageTitle
  const pageSlug = props.pageSlug
  const pageDescription = props.pageDescription
  const pageKeywords = props.pageKeywords
  const pageImage = props.pageImage
  const subTitle = props.subTitle

  // Site Data
  const siteTitle = site.siteMetadata.siteTitle
  const siteTitleEn = site.siteMetadata.siteTitleEn
  const siteDescription = site.siteMetadata.siteDescription
  const siteDescriptionEn = site.siteMetadata.siteDescriptionEn
  const siteKeywords = site.siteMetadata.siteKeywords
  const siteKeywordsEn = site.siteMetadata.siteKeywordsEn
  const siteUrl = site.siteMetadata.siteUrl
  const ogpImage = site.siteMetadata.ogpImage
  const siteAuthor = site.siteMetadata.author

  const url = typeof window !== "undefined" ? window.location.href : ""
  const isLangJa = lang === "ja"
  const isPageSlugHome = pageSlug === "home" || pageSlug === "home-en"

  return (
    <Helmet
      htmlAttributes={{
        lang,
        class: "front",
      }}
      bodyAttributes={{
        class: pageSlug,
      }}
      title={
        isLangJa
          ? pageTitle
            ? `${pageTitle} - ${siteTitle}`
            : `${siteTitle}`
          : pageTitle
          ? `${pageTitle} - ${siteTitleEn}`
          : `${siteTitleEn} - ${subTitle}`
      }
      defaultTitle={isLangJa ? siteTitle : siteTitleEn}
    >
      {/* General tags */}
      <meta
        name="image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        name="description"
        content={
          isLangJa
            ? pageDescription
              ? pageDescription
              : siteDescription
            : pageDescription
            ? pageDescription
            : siteDescriptionEn
        }
      />
      <meta
        name="keyword"
        content={
          isLangJa
            ? pageKeywords
              ? `${pageKeywords},${siteKeywords}`
              : siteKeywords
            : pageKeywords
            ? `${pageKeywords},${siteKeywordsEn}`
            : siteKeywordsEn
        }
      />

      {/* OpenGraph tags */}
      <meta
        property="og:title"
        content={
          isLangJa
            ? pageTitle
              ? `${pageTitle} - ${siteTitle}`
              : `${siteTitle}`
            : pageTitle
            ? `${pageTitle} - ${siteTitleEn}`
            : `${siteTitleEn}`
        }
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        property="og:description"
        content={
          isLangJa
            ? pageDescription
              ? pageDescription
              : siteDescription
            : pageDescription
            ? pageDescription
            : siteDescriptionEn
        }
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteAuthor} />
      <meta
        name="twitter:title"
        content={
          isLangJa
            ? pageTitle
              ? `${pageTitle} - ${siteTitle}`
              : `${siteTitle}`
            : pageTitle
            ? `${pageTitle} - ${siteTitleEn}`
            : `${siteTitleEn}`
        }
      />
      <meta
        name="twitter:image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        name="twitter:description"
        content={
          isLangJa
            ? pageDescription
              ? pageDescription
              : siteDescription
            : pageDescription
            ? pageDescription
            : siteDescriptionEn
        }
      />

      {/* Alternate pages */}
      <link
        rel="alternate"
        hreflang="ja"
        href={isPageSlugHome ? siteUrl + "/ja/" : siteUrl + "/ja/" + pageSlug}
      />
      <link
        rel="alternate"
        hreflang="en"
        href={isPageSlugHome ? siteUrl + "/en/" : siteUrl + "/en/" + pageSlug}
      />

      <link
        rel="canonical"
        href={
          isLangJa
            ? isPageSlugHome
              ? siteUrl
              : siteUrl + "/" + pageSlug
            : isPageSlugHome
            ? siteUrl + "/en/"
            : siteUrl + "/en/" + pageSlug
        }
      />

      {/* MailerLite Universal */}
      <script>
        {`
      (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
      var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
      f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
      var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
      _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

      var ml_account = ml('accounts', '1843010', 'a3q4u2e8c0', 'load');
      `}
      </script>
      {/* End MailerLite Universal */}
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `ja`,
// }

SEO.propTypes = {
  description: PropTypes.string,
  // lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default SEO
