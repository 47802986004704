import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "./BackgroundImage"

const IntroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      introImage: file(relativePath: { eq: "home/img_intro.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const introImage = data.introImage.childImageSharp.fluid

  return <BackgroundImage bgImage={introImage} className="section-bg__main" />
}
export default IntroImage
