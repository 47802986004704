import React from "react"
import { Link } from "gatsby"
import Loadable from "@loadable/component"
import logo from "../assets/images/common/logo-thumb-black.svg"

const LoadableSocial = Loadable(() => import("./Social"))
const LoadableNewsletter = Loadable(() => import("./Newsletter"))

const Footer = props => {
  const lang = props.lang
  let isLangJa = lang === "ja"
  let langDir

  if (isLangJa) {
    langDir = "/jp/"
  } else {
    langDir = "/en/"
  }

  return (
    <footer className="footer">
      <div className="inner container">
        <div className="guide">
          <div className="guide-item guide-item--brand">
            <img src={logo} alt="Kominka Misawaya" />
            {(() => {
              if (isLangJa) {
                return (
                  <p className="brand__text">
                    古民家箕澤屋は
                    <br />
                    信州伊那谷で、
                    <br className="only-for-small" />
                    江戸時代の雰囲気を感じられる
                    <br />
                    ちょっと素敵な空間です。
                  </p>
                )
              } else {
                return (
                  <p className="brand__text--en">
                    Experience History
                    <br />
                    in a traditional Japanese house
                  </p>
                )
              }
            })()}
          </div>
          <div className="guide-item guide-item--nav font-display">
            <Link to={langDir} className="guide-item__link">
              HOME
            </Link>
            <Link to={langDir + "#intro"} className="guide-item__link">
              ABOUT
            </Link>
            <Link to={langDir + "#projects"} className="guide-item__link">
              PROJECTS
            </Link>
            <Link to={langDir + "#location"} className="guide-item__link">
              LOCATION
            </Link>
            {(() => {
              if (isLangJa) {
                return (
                  <a
                    href="https://misawayanohanashi.com/"
                    className="guide-item__link"
                  >
                    BLOG
                  </a>
                )
              }
            })()}
          </div>
          <div className="guide-item guide-item--social">
            <LoadableSocial />
          </div>
        </div>

        <div className="follow">
          <div className="follow-item follow-item--newsletter">
            <h4
              className={`follow-head font-display ${
                isLangJa ? "" : "follow-head--en"
              }`}
            >
              {isLangJa ? "ニュースレター" : "Newsletter"}
            </h4>
            {isLangJa ? (
              <p>
                メンバーの活動やイベント情報などをお届け予定です。
                <br />
                箕澤屋にご興味ある方はこちらからどうぞ。
              </p>
            ) : (
              <p className="follow-text--en">Join us and see our challenges!</p>
            )}
            <LoadableNewsletter lang={lang} />
          </div>

          <div
            className={`follow-item follow-item--caption ${
              isLangJa ? "" : "follow-item--caption--en"
            }`}
          >
            {isLangJa ? (
              <p>
                箕澤屋は、小原家の子孫である家族と、箕澤屋を残したいとの想いで集まったメンバーによって運営されている古民家保存を目的とした活動団体です。
              </p>
            ) : (
              <>
                <p>
                  We are a small organization with people who gathered for
                  Misawaya preservation.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="copyright font-display container">
        © {new Date().getFullYear()} Misawaya.
        <Link to={langDir + "privacy/"} className="link-ul">
          Privacy Policy
        </Link>
      </div>
    </footer>
  )
}

export default Footer
