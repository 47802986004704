import React from "react"
import PropTypes from "prop-types"

import SEO from "../components/SEO"
import "../assets/scss/styles.scss"
import Header from "./Header"
import Footer from "./Footer"

const Layout = props => {
  const {
    pageSlug,
    lang,
    location,
    children,
    pageTitle,
    subTitle,
    pageKeywords,
    pageDescription,
    pageImage,
  } = props

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <>
      <SEO
        location={location}
        pageSlug={pageSlug}
        lang={lang}
        subTitle={subTitle}
        pageTitle={pageTitle}
        pageKeywords={pageKeywords}
        pageDescription={pageDescription}
        pageImage={pageImage}
      />
      <Header pageSlug={pageSlug} lang={lang} location={location} />
      <main>{children}</main>
      <Footer lang={lang} />
    </>
  )
}

// Layout.defaultProps = {
//   lang: `ja`,
// }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
