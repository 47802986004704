import React from "react"

const Button = props => {
  const text = props.text
  const icon = props.icon
  return (
    <>
      <div className="button-custom font-display">
        {text}
        <span className={`ico-${icon}`}></span>
      </div>
    </>
  )
}

export default Button
