import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Social from "./Social"
import Logo from "./Logo"
// import logo from "../assets/images/common/logo-thumb-white.svg"

const Header = props => {
  const lang = props.lang
  const pageSlug = props.pageSlug
  const location = props.location
  // const rootPath = `${__PATH_PREFIX__}/`

  const isLangEn = lang === "en"
  const isLangJa = lang === "ja"

  const [menu, setMenu] = useState(false)

  let langDir

  if (isLangEn) {
    langDir = "/en/"
  } else {
    langDir = "/jp/"
  }

  useEffect(() => {
    // Only for home & en home page
    if (location.pathname === "/jp/" || location.pathname === "/en/") {
      const header = document.querySelector(".header")
      const hero = document.querySelector(".hero")
      const heroHeight = hero.offsetHeight - 80

      const ornament1 = document.querySelector(".ornament-text--left")
      const ornament2 = document.querySelector(".ornament-text--right")

      window.addEventListener("scroll", handleScroll)

      // Prevent body scrolling in modal background
      if (menu === false) {
        document.body.classList.remove("lock-sp")
      } else {
        document.body.classList.add("lock-sp")
      }

      // Add class when user scroll
      function handleScroll() {
        let scrollY = window.pageYOffset

        if (scrollY > heroHeight) {
          header.classList.add("is-scroll")
          ornament1.classList.add("is-scroll")
          ornament2.classList.add("is-scroll")
        } else {
          header.classList.remove("is-scroll")
          ornament1.classList.remove("is-scroll")
          ornament2.classList.remove("is-scroll")
        }
      }
    } else {
    }
  })

  return (
    <>
      <header className={`header ${pageSlug}`}>
        <nav className={`nav-global container ${menu ? "is-open" : ""}`}>
          <button
            className={`nav-button ${menu ? "is-active" : ""}`}
            type="button"
            onClick={() => setMenu(!menu)}
          >
            <span className="ico-humbarger"></span>
          </button>

          <div className="nav-logo">
            <Link to={langDir}>
              <Logo />
            </Link>
          </div>

          <div className={`nav-menu ${menu ? "is-active" : ""}`}>
            <ul className="nav-menu__lists no-bullet font-display">
              <li className="nav-menu__list nav-menu__list--primary">
                <Link
                  to={langDir + "#intro"}
                  className="nav-menu__link nohover"
                  onClick={() => setMenu(!menu)}
                >
                  ABOUT
                </Link>
              </li>
              <li className="nav-menu__list nav-menu__list--primary">
                <Link
                  to={langDir + "#projects"}
                  className="nav-menu__link nohover"
                  onClick={() => setMenu(!menu)}
                >
                  PROJECTS
                </Link>
              </li>
              <li className="nav-menu__list nav-menu__list--primary">
                <Link
                  to={langDir + "#location"}
                  className="nav-menu__link nohover"
                  onClick={() => setMenu(!menu)}
                >
                  LOCATION
                </Link>
              </li>
              {isLangJa && (
                <li className="nav-menu__list nav-menu__list--primary">
                  <a
                    href="https://misawayanohanashi.com/"
                    className="nav-menu__link nohover"
                  >
                    BLOG
                  </a>
                </li>
              )}
              <li className="nav-menu__list--social">
                <Social />
              </li>
            </ul>
          </div>

          <div className={`nav-lang ${lang}`}>
            <div
              className={`nav-lang__list nav-lang__list--ja${
                isLangJa ? " current" : ""
              }`}
            >
              <a href="/jp/" className="nav-lang__link nohover">
                JP
              </a>
            </div>
            <div
              className={`nav-lang__list nav-lang__list--en${
                isLangEn ? " current" : ""
              }`}
            >
              <a href="/en/" className="nav-lang__link nohover">
                EN
              </a>
            </div>
            <div className="nav-lang__line"></div>
          </div>
        </nav>
        <aside className="ornament-text ornament-text--right font-display">
          INADANI NAGANO
        </aside>
        <aside className="ornament-text ornament-text--left font-display">
          KOMINKA MISAWAYA
        </aside>
      </header>
    </>
  )
}

export default Header
