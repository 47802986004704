import React from "react"

const Logo = () => (
  <svg
    id="logo-svg"
    data-name="logo-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72.57 72.01"
  >
    <path d="M.86,62.09A14.29,14.29,0,0,0,9.17,71" />
    <path d="M0,25.62A46.62,46.62,0,0,0,11.51,8.68C13.29,3.83,12.47,1,14,0A14.2,14.2,0,0,0,0,14.17V25.62" />
    <path d="M0,27.5.08,57.38s10.72-.12,13-.12V34.07c-2.82-.55-8.32,1.43-9.63-1.58-.17-1-1.2-2.92.48-3a62.06,62.06,0,0,0,9.18.45c0-2.22,0-4,0-6.18-.05-1.9,2.3-1,3.47-1.11,1.09,0,3,.47,3.19,1.54.05.51-.46,5.19-.34,5.53h14c-4.41-5.53-8.67-9.95-12.94-15.3-2.57,0-2.76,0-5.32,0-.26,0-.41.29-.56.53C11.24,20.21,6.75,27.1,0,27.5" />
    <path d="M9.17,71c2.22-2.81,4-5,4-9-2.53,0-8.21.15-12.34.05" />
    <path d="M17,9.2C25.74,9,36.91,8.23,45.15,8c.5-1,1.76-7.37,2.91-8H18.7C20.7,1.19,17.91,5.76,17,9.2Z" />
    <path d="M53.76,33.82c-13.21.11-21,.22-34.15.34v5.11c13.22-.08,21-.17,34.15-.25Z" />
    <path d="M71.34,12.78c-2.9.79-9.66.27-14.76.36.22.26,3.09.85,4.83,1.69,4.17,2,4.06,2.33,7.22,4.76,2.78,2.67-2.2,4.2-3.66,3.32-.75-.09-9-8.24-12.37-9.57a26.59,26.59,0,0,0-3.57.17C45.53,18,40.87,21.44,36.69,25.3c.82,1.39,3.19,3.16.93,4.27,0,0,0,.09-.06.18,6.64-.09,9.34-.17,16-.25A33.63,33.63,0,0,0,53,24.61c-.45-1.64-1.71-4.91,1.39-4.76,9.14.35,6,2.2,6.31,9.53A28.18,28.18,0,0,0,65.39,29c2.34-.52,5.13,1.3,4.47,3.78-.93,1.78-7.09.63-9.16.95V57.18c1.32,0,11.87-.12,11.87-.12C72,52,73.91,16.4,71.34,12.8" />
    <path d="M70.46,7.38C67.06.81,59.87-.55,52.91,0c2,1.86-.75,5.77-1.58,7.88,7.19,0,12.05-.76,19.13-.51" />
    <path d="M71.63,61.78c-2.11,0-8.76-.06-11-.06A15.94,15.94,0,0,0,65.25,70a15.22,15.22,0,0,0,6.38-8.23" />
    <path d="M19.61,47.77c13.24-.11,21-.21,34.18-.31V43.15c-13.22.11-21,.22-34.18.33Z" />
    <path d="M42.8,13.64l-17.08.55c3.09,3.35,6,6,8.94,9.21C37.73,20.36,40.56,17.63,42.8,13.64Z" />
    <path d="M15,72H58c.44,0,.87,0,1.3-.07" />
    <path d="M60.93,71.94c-4-1.15-7.1-5.6-7.21-10h-34C19.54,66,18.74,70,15,72" />
    <path d="M19.59,57.45c13.26-.09,21.05-.17,34.15-.26V51.76c-13.25.12-21,.23-34.15.35Z" />
  </svg>
)
export default Logo
