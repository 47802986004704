import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import icoInstagram from "../assets/images/social/ico-instagram.svg"
import styled from "styled-components"

const SocialWrapper = styled.div`
  .socialLink {
    display: inline-block;
    transition: transform 0.3s;
    /* padding-right: 1.5rem; */
    &:last-child {
      /* padding-right: 0; */
    }
    svg {
      width: 2rem;
    }
    -webkit-backface-visibility: hidden;
    &:hover {
      @media (min-width: 750px) {
        transform: translateY(-3px);
      }
    }
  }
`

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          author
          social {
            instagram
            facebook
          }
        }
      }
    }
  `)

  return (
    <SocialWrapper>
      <a
        className="socialLink"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://instagram.com/${data.site.siteMetadata.social.instagram}`}
      >
        <svg
          id="instagram"
          data-name="instagram"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56.69 56.69"
        >
          <title>Instagram</title>
          <path d="M28.35,5.32c7.49,0,8.38,0,11.34.17a15.61,15.61,0,0,1,5.22,1,8.71,8.71,0,0,1,3.23,2.11,8.69,8.69,0,0,1,2.1,3.22,15.61,15.61,0,0,1,1,5.22c.14,3,.17,3.85.17,11.35s0,8.38-.17,11.34a15.61,15.61,0,0,1-1,5.22,9.29,9.29,0,0,1-5.33,5.33,15.61,15.61,0,0,1-5.22,1c-3,.14-3.84.17-11.34.17S20,51.34,17,51.2a15.61,15.61,0,0,1-5.22-1,8.69,8.69,0,0,1-3.22-2.1,8.56,8.56,0,0,1-2.1-3.23,15.29,15.29,0,0,1-1-5.22c-.14-3-.17-3.85-.17-11.34S5.35,20,5.49,17a15.29,15.29,0,0,1,1-5.22,8.45,8.45,0,0,1,2.1-3.22,8.59,8.59,0,0,1,3.22-2.11,15.61,15.61,0,0,1,5.22-1c3-.14,3.85-.17,11.35-.17m0-5.06c-7.63,0-8.59,0-11.58.17A20.58,20.58,0,0,0,10,1.74,13.85,13.85,0,0,0,5,5a13.85,13.85,0,0,0-3.24,5A20.58,20.58,0,0,0,.43,16.77c-.13,3-.17,3.95-.17,11.58s0,8.58.17,11.57a20.58,20.58,0,0,0,1.31,6.82,13.76,13.76,0,0,0,3.24,5A13.59,13.59,0,0,0,10,55a20.58,20.58,0,0,0,6.82,1.31c3,.14,3.95.17,11.58.17s8.58,0,11.57-.17A20.58,20.58,0,0,0,46.74,55,14.39,14.39,0,0,0,55,46.74a20.58,20.58,0,0,0,1.31-6.82c.14-3,.17-4,.17-11.57s0-8.59-.17-11.58A20.58,20.58,0,0,0,55,10a13.59,13.59,0,0,0-3.24-5,13.76,13.76,0,0,0-5-3.24A20.58,20.58,0,0,0,39.92.43c-3-.13-4-.17-11.57-.17ZM28.4,14A14.43,14.43,0,1,0,42.83,28.4,14.43,14.43,0,0,0,28.4,14Zm0,23.8a9.37,9.37,0,1,1,9.37-9.37,9.37,9.37,0,0,1-9.37,9.37ZM46.62,13.3A3.34,3.34,0,1,1,43.28,10a3.34,3.34,0,0,1,3.34,3.34Zm0,0" />
        </svg>
      </a>
      <a
        className="socialLink"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://facebook.com/${data.site.siteMetadata.social.facebook}`}
      >
        <svg
          id="facebook"
          data-name="facebook`"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56.69 56.69"
        >
          <title>Facebook</title>
          <path
            className="facebook-path"
            d="M56.43,28.38A28.09,28.09,0,1,0,24,56.12V36.5H16.83V28.38H24V22.2c0-7,4.19-10.93,10.61-10.93a43,43,0,0,1,6.28.55v6.91H37.31c-3.49,0-4.58,2.16-4.58,4.38v5.27h7.79L39.28,36.5H32.73V56.12A28.08,28.08,0,0,0,56.43,28.38Z"
          />
        </svg>
      </a>
      <a
        className="socialLink"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UCnpXiESyTEzvKkIrmM85fyQ"
      >
        <svg
          id="youtube"
          data-name="youtube"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56.69 56.69"
        >
          <title>YouTube</title>
          <path
            className="youtube-path"
            d="M55.25,14.84a7.05,7.05,0,0,0-5-5c-4.39-1.18-22-1.18-22-1.18s-17.58,0-22,1.18a7.05,7.05,0,0,0-5,5C.18,19.26.18,28.47.18,28.47s0,9.21,1.18,13.63a7.05,7.05,0,0,0,5,5c4.39,1.18,22,1.18,22,1.18s17.59,0,22-1.18a7.05,7.05,0,0,0,5-5c1.18-4.42,1.18-13.63,1.18-13.63S56.43,19.26,55.25,14.84Zm-32.7,22V20.11l14.7,8.36Z"
          />
        </svg>
      </a>
    </SocialWrapper>
  )
}

export default Social
